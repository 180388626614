<template>
  <van-nav-bar
    :title="title"
    left-text="返回"
    left-arrow
    @click-left="goBack"
  />
</template>

<script>
  export default {
    name: 'navBar',
    props: {
      title: String
    },
    methods: {
      //  返回
      goBack () {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .van-nav-bar__text {
    color: #323233;
  }

  .van-nav-bar /deep/ .van-icon {
    color: #323233;
  }
</style>
