<template>
  <div class="page-container">
    <navBar title="估价"></navBar>
    <div class="header">
      <div class="title-wrapper">
        <div class="title">{{productName}}</div>
        <div class="price">{{price}}</div>
      </div>
    </div>
    <scroll class="content">
      <div class="selected-property-wrapper" @click="handleFold">
        <div class="left">已选择</div>
        <div class="mid">
          <template v-for="(item, name) in selectedObj">
            <span v-if="name != 'level'">{{item.title}}</span>
          </template>

        </div>
        <van-icon class="right" v-if="isShowSelecte" name="arrow-up"/>
        <van-icon class="right" v-else name="arrow-down"/>
      </div>

      <div class="property-list-wrapper" v-if="isShowSelecte">
        <div class="property-item" v-for="(item,index) in productDesc" :key="index">
          <div class="title">{{item.title}}</div>
          <div class="btn-list">
            <div class="btn"
                 :class="[selectedObj[item.name].id==itemBtn.id ? 'active':'',item.name == 'smallmodel'?'model-button': '']"
                 v-for="itemBtn in item.list" :key="itemBtn.id"
                 @click="selectProperty(item.name, itemBtn)"
            >
              {{itemBtn.title}}
            </div>
          </div>
        </div>
      </div>

      <div class="property-list-wrapper">
        <div class="property-item">
          <div class="title">成色</div>
          <div class="btn-list">
            <div class="btn" :class="item.id == activeLevelId?'active':''" v-for="item in levelList" :key="item.id"
                 @click="selectLevel(item)">
              {{item.name}}
            </div>
          </div>
          <div class="level-desc">{{activeLevelDesc}}</div>
        </div>
      </div>

      <div class="property-list-wrapper">
        <div class="property-item">
          <div class="title">扣费项</div>
          <div class="deduction-list">
            <div class="deduction-item" v-for="item in feesList" :key="item.id">
              <div class="tit">{{item.title}}</div>
              <div class="cost">减{{item.cost}}</div>
              <div class="desc" @click="descClickHandle(item.desc)">查看标准</div>
            </div>

          </div>
        </div>
      </div>

      <div class="bottom-tips">
        <div>注意事项：</div>
        <div>符合标准按报价收，本司拒收脏机，有锁机器一律退回</div>
      </div>
    </scroll>
  </div>
</template>

<script>
  import { getModelDetail, getPrice } from 'api/api'
  import navBar from 'components/common/navBar/navBar'
  import Scroll from 'components/common/scroll/Scroll'

  export default {
    name: 'evaluation',
    components: {
      navBar,
      Scroll
    },
    data () {
      return {
        isShowSelecte: true,
        productId: '',
        productName: '',
        productDesc: null,
        selectedObj: null,
        levelList: [],
        feesList: [],
        activeLevelId: '',
        activeLevelDesc: '',
        price: '',
      }
    },
    methods: {
      async init () {
        await this.getModelDetail()
        this.getPrice()
      },
      handleFold () {
        this.isShowSelecte = !this.isShowSelecte
      },
      getModelDetail () {
        this.productId = Number(this.$route.query.id)
        let params = {
          id: this.productId
        }
        return getModelDetail(params).then(res => {
          this.productName = res.name
          this.productDesc = res.attribute
          this.selectedObj = res.default_attr
          this.levelList = res.level
          this.feesList = res.fees
          this.activeLevelId = res.default_attr.level.id
          this.activeLevelDesc = res.default_attr.level.desc
        })
      },
      //查看标准弹窗
      descClickHandle (e) {
        this.$dialog.alert({
          message: e,
          confirmButtonColor: '#1989fa'
        }).then(() => {
          // on close
        })
      },
      getPrice () {
        let params = {
          id: this.productId
        }
        //遍历已选中
        for (let key in this.selectedObj) {
          params[key] = this.selectedObj[key].id
        }
        getPrice(params).then(res => {
          this.price = res.reference_price
        })
      },
      selectProperty (type, btnObj) {
        this.$set(this.selectedObj, type, btnObj)
        this.getPrice()
      },
      selectLevel (obj) {
        this.$set(this.selectedObj, 'level', obj)
        this.getPrice()
      },
    },
    watch: {
      'selectedObj.level': {
        handler (newVal, oldVal) {
          this.activeLevelId = newVal.id
          this.activeLevelDesc = newVal.desc
        },
        deep: true
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style lang="less" scoped>
  .page-container {
    height: 100vh;
    overflow: hidden;

    .content {
      height: calc(100vh - 46px);
    }
  }

  .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    display: flex;
    padding: 0.3rem;
    border-bottom: 1px solid #e5e5e5;
    background: #fff;
    z-index: 1;

    .title-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 0.7rem;

      .title {
        font-size: 0.38rem;
        font-weight: bold;
      }

      .price {
        color: #ff5100;
        font-weight: bold;
        font-size: 0.4rem;
        padding-left: 0.2rem;
      }
    }
  }

  .selected-property-wrapper {
    background: #fff;
    padding: 1.5rem 0.3rem 0.2rem 0.3rem;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;

    .left {
      flex: 0 0 4em;
      font-size: 0.28rem;
    }

    .mid {
      flex: auto;
      color: #ff5100;
      font-size: 0.32rem;

      span {
        margin-right: 0.15rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .right {
      flex: 0 0 0.4rem;
      height: 0.4rem;
    }
  }

  .property-list-wrapper {
    background: #fff;
    padding: 0.3rem 0.3rem 0.1rem 0.3rem;
    margin-bottom: 0.2rem;

    .title {
      height: 0.6rem;
      margin-bottom: 0.2rem;
      font-size: 0.36rem;
      color: #000;
    }

    .btn-list {
      display: flex;
      flex-wrap: wrap;
      color: #333;

      .btn {
        width: 31%;
        height: 0.6rem;
        line-height: 0.6rem;
        text-align: center;
        border-radius: 0.3rem;
        background: rgb(245, 245, 247);
        margin: 0 0 0.2rem;
        box-sizing: border-box;
        color: #333;
        font-size: 0.3rem;

        &.model-btn {
          width: auto;
          min-width: 31%;
        }

        &.active {
          color: #ff5100;
          border: 0.02rem solid #ff5100;
          background: #fff;
          line-height: 0.56rem;
        }

        &:not(:nth-child(3n)) {
          margin-right: calc(7% / 2);
        }
      }
    }

    .level-desc {
      background: rgb(255, 251, 229);
      padding: 0.3rem;
      color: #666;
      border-radius: 0.16rem;
      margin-bottom: 0.25rem;
      font-size: 0.28rem;
    }

    .deduction-item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 0.2rem;

      .tit {
        flex: auto;
        background-color: #eee;
        border-radius: 0.1rem;
        box-sizing: border-box;
        text-align: center;
        line-height: 0.4rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: #555;
        padding: 0.1rem 0.17rem;
      }

      .cost {
        flex: 0 0 35%;
        font-size: 0.28rem;
        text-align: center;
      }

      .desc {
        height: 0.44rem;
        padding: 0 0.17rem;
        background-color: #5ab87a;
        border-radius: 0.21rem;
        text-align: center;
        line-height: 0.44rem;
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  .bottom-tips {
    font-size: 0.28rem;
    padding: 0.3rem;
    color: #a3a3a3;
  }
</style>
